import React, { useState, useEffect } from "react";
import { Canvas, useThree } from "@react-three/fiber";
import { useLoader } from "@react-three/fiber";
import * as THREE from "three";
import { OrbitControls } from "@react-three/drei";

import "./pano.scss";
import gsap from "gsap";

const PanoViewer = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [passkey, setPasskey] = useState("");
  const [formState, setFormState] = useState("");

  const texture = useLoader(
    THREE.TextureLoader,
    "https://zmgxlzhyy6odwgom.public.blob.vercel-storage.com/Hyundai_CretaEV_INT-360_R06-EVMrG49FbrYO9aZLrInAlw4n3BWn2f.jpg"
  );

  // Flip the texture horizontally
  texture.wrapS = THREE.RepeatWrapping;
  texture.repeat.x = -1;

  const SetInitialView = () => {
    const { camera } = useThree();

    useEffect(() => {
      camera.position.set(-10, 0, 0.1); // Optional: Set initial position if needed
      camera.rotation.set(0, Math.PI / 2, 0); // Adjust the rotation for initial view
    }, [camera]);

    return null;
  };

  const handlePasswordSubmit = () => {
    if (passkey === "DQTwqxW-K4YZ") {
      sessionStorage.setItem("authenticated", "true");
      setFormState("success");

      setTimeout(() => {
        setFormState(""); // Clear the form state after 2 seconds
      }, 2000);

      // Fade out the modal
      gsap.to("#modal", {
        opacity: 0,
        duration: 2,
        delay: 1,
        onComplete: () => {
          setIsAuthenticated(true);
        },
      });
    } else {
      setFormState("error");

      setTimeout(() => {
        setFormState(""); // Clear the form state after 2 seconds
      }, 2000);
    }
  };

  useEffect(() => {
    const authStatus = sessionStorage.getItem("authenticated");
    if (authStatus === "true") {
      setIsAuthenticated(true);
    }
  }, []);

  return (
    <section className="panoview">
      <div className="container" style={{ width: "100vw", height: "100vh" }}>
        <Canvas linear flat dpr={[1, 5]}>
          <SetInitialView />
          <mesh>
            <sphereGeometry args={[500, 60, 40]} />
            <meshBasicMaterial map={texture} side={THREE.BackSide} />
          </mesh>
          <OrbitControls
            enableZoom={true}
            zoomSpeed={0.5}
            minDistance={10}
            maxDistance={400}
            reverseOrbit={true}
            enablePan={false}
            enableRotate={true}
            enableDamping={true}
            dampingFactor={0.1}
            touches={{
              ONE: THREE.TOUCH.ROTATE,
              TWO: THREE.TOUCH.DOLLY_PAN, // Allow pinch-zoom
            }}
          />
        </Canvas>
      </div>
      <div className={isAuthenticated ? "modal none" : "modal"} id="modal">
        <div className="modalContent">
          <div className={`form ${formState}`}>
            {/* <h3>Please enter the password</h3> */}
            <input
              type="password"
              value={passkey}
              onChange={(e) => setPasskey(e.target.value)}
              placeholder="Please enter the passkey"
            />
            <button
              onClick={handlePasswordSubmit}
              disabled={passkey.length > 2 ? false : true}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PanoViewer;
