import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "../pages/home";
import PanoViewer from "../pages/pano-viewer";
// import Ev02 from "../pages/ev-02";

const AppRoutes = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/ev-01" element={<PanoViewer />} />
        {/* <Route exact path="/ev-02" element={<Ev02 />} /> */}
      </Routes>
    </BrowserRouter>
  );
};

export default AppRoutes;
